import React from 'react'
import Logo from './Logo'
import Box from '@mui/material/Box'
import { Stack } from '@mui/material'

interface HeaderProps {
	logoSrc: string
	logoAlt: string
	title: string
	subtext: string
}

const Header: React.FC<HeaderProps> = ({ logoSrc, logoAlt, title, subtext }) => {
 return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			flexDirection="row"
			bgcolor="primary.light"
			padding={1}
		>
			<Stack
				direction={{ xs: 'column', sm: 'row' }}
				spacing={{ xs: 1, sm: 2, md: 4 }}
			>
				<Box sx={{ textAlign: 'center' }}>
					<Logo src={logoSrc} alt={logoAlt} />
				</Box>
				<Box color="primary.dark">
					<h1>{title}</h1>
					<p style={{ margin: '0', marginTop: '-20px' }}>{subtext}</p>
				</Box>
		</Stack>
	</Box>
	);
};


export default Header