import { Box, Button, Grid, IconButton, InputAdornment, TextField } from '@mui/material'
import WalletButton from './Button'; // Assuming you have WalletButton component
import React from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import Delete from '@mui/icons-material/Delete';

interface WalletProps {
	id: number
	index: number
	onChangeHandler: (arg0: number, arg1: string) => void
	onClickHandler: (arg0: number) => void
	buttonType: string
	setSubmitForm?: React.Dispatch<React.SetStateAction<boolean>>
}

interface AddHandler {
	(): void
}

interface RemoveHandler {
	(arg0: number): void
}

const buttonHandler = (button: string, handler: RemoveHandler | AddHandler, id: number) => {
	switch (button) {
		case 'REMOVE':
			return (
				<Box display='inline-block' sx={{ cursor: 'pointer' }}>
					<InputAdornment onClick={() => (handler as RemoveHandler)(id)} position='end'>
						<IconButton>
							<Delete sx={{ color: 'primary.dark' }}></Delete>
						</IconButton>
					</InputAdornment>
				</Box>
			)
		case 'ADD':
			return (
				<WalletButton onClick={handler as AddHandler} label='+Wallet' />
			)
		default:
			return ('')
	}
}
const Wallet: React.FC<WalletProps> = ({ id, index, onChangeHandler, onClickHandler, buttonType, setSubmitForm }) => {
	return (
		<Box>
			<Box color='white'>
				<Box component='span' sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>
					Wallet {index < 9 ? '0' + (index + 1).toString() : index + 1}
				</Box>
			</Box>
			<TextField
				variant="standard"
				onChange={(e) => onChangeHandler(index, e.target.value)}
				InputProps={{ endAdornment: (buttonHandler(buttonType, onClickHandler, id)) }}
				sx={{
					bgcolor: 'primary.light',
					borderRadius: '8px',
					border: '1px solid gray',
					width: '100%',
					'& .MuiInput-underline:before': { // underline 'before' pseudo-class
						borderBottom: 'none', // remove underline
					},
					'& .MuiInput-underline:after': { // underline 'after' pseudo-class
						borderBottom: 'none', // remove underline
					},
					'& .MuiInput-underline:hover:not(.Mui-disabled):before': { // underline hover state
						borderBottom: 'none', // remove underline
					},
				}}
			/>
		</Box>
	)
}

export default Wallet