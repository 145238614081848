import React from 'react';
import { Button } from '@mui/material';

interface MyButtonProps {
  onClick: () => void;
  label: string;
}

const MyButton: React.FC<MyButtonProps> = ({ onClick, label }) => (
  <Button 
    onClick={onClick}
    sx={{
      borderTopRightRadius: '8px',
      borderBottomRightRadius: '8px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      backgroundColor: 'primary.main', 
      color: 'primary.dark',
      '&:hover': {
        backgroundColor: 'primary.main', // Adjust if needed
      },
      fontWeight: 'bold',
      borderLeft: '1px solid gray',
    }}
  >
    {label}
  </Button>
);

export default MyButton;
