import React, { useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import axios from 'axios'
import SelectMenu from '../components/SelectMenu'
import WalletForm from '../components/WalletForm'
import resources from '../resources'
import { ILettuceApiState, ILettuceUISState, ILettuceApiResponse } from '../types'
import { nativeNetworkByNativeCurrency, FiatCurrencies, tokenOptions } from '../consts'

export interface HomeProps extends ILettuceUISState, ILettuceApiState {
	token: string
	fiatCurrency: string
	walletAddresses: string[]
	formSubmitted: boolean
	setWeb3Token: React.Dispatch<React.SetStateAction<string>>
}

const Home: React.FC<HomeProps> = ({ token, setNavigation, fiatCurrency,
	setFiatCurrency, walletAddresses, setWalletAddresses, setFormSubmitted,
	formSubmitted, setApiResponse, setProgress, setWeb3Token }) => {

	console.log('form submitted', formSubmitted)

	useEffect(() => {
		if (formSubmitted) {
			setProgress(0)
		}
	}, [formSubmitted])

	const handleSubmit = async () => {
		try {
			console.log('sending request to localhost:3000')
			console.log('form submitted', formSubmitted)

			setNavigation(resources.DCA)

			// Define the request parameters
			interface RequestParams {
				network: string;
				wallets: string[];
				currency: string;
				token: string;
			}

			const params: RequestParams = {
				network: nativeNetworkByNativeCurrency[token],
				wallets: walletAddresses,
				currency: fiatCurrency,
				token
			};

			const queryParams = new URLSearchParams(params as any).toString();
			// TODO Environment based url interactions
			const url = `https://api.lettucedca.com?${queryParams}`;
			axios.get<ILettuceApiResponse>(url, { withCredentials: true }).then(response => {
				console.log(response.data);
				setProgress(100)
				setApiResponse(response.data.cumulative)
			}).catch(error => {
				console.error('Error fetching data:', error);
			})
		} catch (e) {
			console.log(e)
		} finally {
			setFormSubmitted(false)
		}

	}

	return (
		<Grid container rowGap={3} direction='column' sx={{ paddingTop: '50px' }}>
			<Grid item xs={12}>
				<Box sx={{ gridArea: 'label', }} >
					<h2 style={{ color: 'primary.light', margin: 0 }}>FIAT COST AVERAGE CURRENCY</h2>
				</Box>
				<Grid container direction='row' rowGap={2}>
					<Grid item xs={12} md={7}>
						<SelectMenu options={FiatCurrencies}
							value={fiatCurrency}
							onChange={(value) => setFiatCurrency(value)} />
					</Grid>
					<Grid item xs={false} md={2}></Grid>
					<Grid item xs={12} md={3} >
						<Box sx={{ display: { xs: 'none', md: 'block' }	}}>
							<SelectMenu
								options={tokenOptions}
								value={token}
								onChange={(value) => setWeb3Token(value)}
								width={123}
								justify='right'
							/>
						</Box>
						<Box sx={{ display: { xs: 'block', md: 'none' }	}}>
							<SelectMenu
								options={tokenOptions}
								value={token}
								onChange={(value) => setWeb3Token(value)}
								width={123}
								justify='flex-start'
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={12}>
				<WalletForm addresses={walletAddresses} setState={setWalletAddresses}
					tokenNetwork={token} submitHandler={() => {
						setFormSubmitted(true);
						handleSubmit()
					}} />
			</Grid>
		</Grid>
	)
	// return (
	// 	<Box sx={{
	// 		display: 'grid',
	// 		gridTemplateColumns: 'repeat(6, 1fr)',
	// 		columnGap: 1,
	// 		gridTemplateRows: 'repeat(4, auto)',
	// 	}}>
	// 		<Box sx={{ gridArea: 'label', gridColumn: 'span 6', gridRow: '1' }} >
	// 			<h2 style={{ color: 'primary.light' }}>FIAT COST AVERAGE CURRENCY</h2>
	// 		</Box>
	// 		<Box sx={{ gridArea: 'selectors', gridColumn: 'span 6' }}>
	// 			<SelectMenu options={FiatCurrencies}
	// 				value={fiatCurrency}
	// 				onChange={(value) => setFiatCurrency(value)} />
	// 					<SelectMenu
	// 				options={tokenOptions}
	// 				value={token}
	// 				onChange={(value) => setWeb3Token(value)}
	// 				width={123}
	// 			/>
	// 		</Box>
	// 		<Box sx={{ gridArea:'walletForm', gridRow: '1' }}>
	// 			<WalletForm addresses={walletAddresses} setState={setWalletAddresses}
	// 				tokenNetwork={token} submitHandler={() => {
	// 					setFormSubmitted(true);
	// 					handleSubmit()
	// 				}} />
	// 		</Box>
	// 	</Box>
	// )

	/*
	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Grid container style={{ justifyContent: 'center' }} >
				<Grid item xs={12} xl={6} md={6} sm={8} lg={5}>
					<Grid container rowSpacing={3}>
						<Grid item xs={12}>
							<Grid container >
								<Grid item xs={12}>
								</Grid>
								<Grid item md={7}>
									
								</Grid>
								<Grid item xs={12} md={5} style={{ justifyContent: 'right' }}>
									
								</Grid>

							</Grid>
						</Grid>
						<Grid item xs={12}>
						
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	)*/
}

export default Home