import React from 'react'
import ReactDOM from 'react-dom/client'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Box, CssBaseline } from '@mui/material'
import App from './App'
import reportWebVitals from './reportWebVitals'
declare module '@mui/material/styles' {
  interface PaletteColor {
    lighter?: string
    gradient?: string
  }

  interface SimplePaletteColorOptions {
    lighter?: string
    gradient?: string
  }
}

const theme = createTheme({
  palette: {
    primary: {
      // Main = Buttons
      main: '#92EB88',
      // Dark = Adronments, Button Text
      dark: '#425B5A',
      // Light = Header
      light: '#EAEAEA',
      // Light = Text boxes, drop down menus
      lighter: '#ffffff',
    },
    secondary: {
      main: '#26A89A',
      dark: '#26A89A',
      light: '#DEF066',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    body1: {
      letterSpacing: '0em',
      color: '#ffff'  
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: 'transparent linear-gradient(180deg, #26A89A 0%, #26A89A 0%, #29AFA1 39%, #DEF066 100%) 0% 0% no-repeat padding-box;'
        },
      },
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
