import React, { useState, useEffect, ReactElement } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Wallet from './Wallet';
import { Grid, TextField } from '@mui/material';

interface WalletFormProps {
	addresses: string[]
	setState: React.Dispatch<React.SetStateAction<string[]>>
	tokenNetwork?: string
	submitHandler: () => void
}

function generateNumericId() {
	return Date.now() + Math.floor(Math.random() * 100);
}

const WalletForm: React.FC<WalletFormProps> = ({ addresses, setState, submitHandler }) => {
	const [walletFields, setWalletFields] = useState<number[]>([0]);

	const handleAdd = () => {
		setWalletFields(prevState => [...prevState, generateNumericId()]);
		setState(prevFields => [...prevFields, ""]);  // Add this line
	}

	const handleRemove = (idToRemove: number) => {
		// Find the index of the wallet with the ID to remove
		const indexToRemove = walletFields.indexOf(idToRemove);

		// Create a new array that does not include the wallet with that ID
		setWalletFields(prevState => prevState.filter(id => id !== idToRemove));

		// Create a new array that does not include the address at the same index
		setState(prevFields => prevFields.filter((_, index) => index !== indexToRemove));
	};

	const handleTextChange = (index: number, newValue: string) => {  // Add this function
		setState(prevFields => prevFields.map((value, i) => i === index ? newValue : value));
	}

	return (
		<Grid container rowGap={1}>
			{walletFields.map((id, index, arr) => (
				<Grid item xs={12} key={id}>
					{index === arr.length - 1 ? (
						<Wallet
							id={id}
							index={index}
							onChangeHandler={handleTextChange}
							onClickHandler={handleAdd}
							buttonType={'ADD'}
						/>
					) : (
						<Wallet
							id={id}
							index={index}
							onChangeHandler={handleTextChange}
							onClickHandler={handleRemove}
							buttonType={'REMOVE'}
						/>
					)}
				</Grid>
			))}
			<Grid item xs={12} sx={{ paddingTop: '35px' }}>
				<Button onClick={(_) => { (submitHandler)() }} sx={{
					bgcolor: 'primary.main',
					color: '#52716d',
					minWidth: 'auto',
					boxShadow: 'none',
					borderRadius: '8px',
					whiteSpace: 'nowrap',
					border: '1px solid gray',
					fontWeight: 'bold',
					padding: '3.94px 12px',
					'&:hover': {
						backgroundColor: 'secondary.dark',  // Same as normal state
						boxShadow: 'none',  // Removes hover shadow
					},
				}} variant="contained"
				>
					Find Out
				</Button>
			</Grid>
		</Grid>


	);
}

export default WalletForm;
