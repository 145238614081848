import React, { useState, useRef } from 'react';
import { Box, Button, Menu, MenuItem, IconButton, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Option {
  value: string;
  label: string;
}

interface SelectWithMenuProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  width?: number
  justify?: string
}

const SelectMenu: React.FC<SelectWithMenuProps> = ({ options, value, onChange, width = 366, justify = 'flex-start' }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const currencyButtonRef = useRef(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(currencyButtonRef.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    onChange(value);
    handleClose();
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} >
      <Box sx={{ display: 'flex', padding: '0px', justifyContent: justify}}>
        <Button ref={currencyButtonRef} onClick={(e) => { setAnchorEl(e.currentTarget) }} sx={{
          bgcolor: (theme) => `${theme.palette.primary.light} !important`,  paddingTop: '0px', paddingBottom: '0px', border: '1px solid gray', width: width
        }}
        >
          <Box sx={{ fontWeight: 'bold', paddingLeft: '2em', paddingRight: '2em', color: 'primary.dark'}}>
            {options.find((option) => option.value === value)?.label}
          </Box>
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          {options.map((option) => (
            <MenuItem sx={{color: 'primary.dark'}} key={option.value} onClick={() => handleMenuItemClick(option.value)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
        <Box sx={{
          padding: '0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'primary.main',
          justifyContent: 'space-between',
          borderTopRightRadius: '5px',
          borderBottomRightRadius: '5px',
          marginLeft: '-5px',
          zIndex: '1000',
          border: '1px solid gray',
        }}>
          <IconButton sx={{ margin: 0, borderTopRightRadius: '5px', paddingTop: '4px', paddingBottom: '4px' }} onClick={handleClick}>
            <ExpandMoreIcon sx={{ color: 'primary.dark', borderBottomRightRadius: '5px' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default SelectMenu;
