import React from 'react'
import Box from '@mui/material/Box'

interface LogoProps {
	src: string
	alt: string
	width?: number
}

const Logo: React.FC<LogoProps> = ({ src, alt, width=100 }) => {
	return <Box component="img" src={src} alt={alt} sx={{ width: width, height: 'auto' }} />
}

export default Logo