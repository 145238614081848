export const FiatCurrencies = [
	{ value: 'USD', label: 'US Dollar (USD)' },
	{ value: 'EUR', label: 'Euro (EUR)' },
	{ value: 'JPY', label: 'Japanese Yen (JPY)' },
	{ value: 'GBP', label: 'Pound Sterling (GBP)' },
	{ value: 'AUD', label: 'Australian dollar (AUD)' },
	{ value: 'CAD', label: 'Canadian dollar (CAD)' },
	{ value: 'CHF', label: 'Swiss Franc (CHF)' },
	{ value: 'CNY', label: 'Chinese renminbi (CNY)' },
	{ value: 'SEK', label: 'Swedish Krona (SEK)' },
	{ value: 'NZD', label: 'New Zealand dollar (NZD)' }
]

export const tokenOptions = [
	{ value: 'XRP', label: 'XRP' },
	{ value: 'HBAR', label: 'HBAR' },
]

export const nativeNetworkByNativeCurrency: { [key: string]: string } = {
	'XRP': 'XRPL',
	'HBAR': 'Hedera'
}

export const currencyFormatter = (value: number, currency: string): string => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: currency,
		minimumFractionDigits: 2, // always use at least two decimal points
		maximumFractionDigits: 2, // do not use more than two decimal points
	})

	return formatter.format(value)
}

export const percentFormatter = (value: number): string => {
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'percent',
		minimumFractionDigits: 2, // always use at least two decimal points
		maximumFractionDigits: 2, // do not use more than two decimal points
	})

	return formatter.format(value)
}

export const regNumFormatter = (value: number): string => {
	const formatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 2, // always use at least two decimal points
		maximumFractionDigits: 2, // do not use more than two decimal points
	})

	return formatter.format(value)
}
