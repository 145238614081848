import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import hbarGif from '../animations/HBAR.gif';
import xrpGif from '../animations/XRP.gif'
import Logo from './Logo';
let logo: string;

interface FooterProps {
  network: string
}

const Footer: React.FC<FooterProps> = ({ network }) => {
  let address = ''
  switch (network) {
    case 'HBAR':
      logo = hbarGif
      address = '0.0.3096584'
      break;
    case 'XRP':
      logo = xrpGif
      address = 'rM6aoy1ixXQQAXcp9RNKHaf5Houa72JZ5a'
      break;
    default:

  }
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="row"
      sx={{
        width: '100%',
        backgroundColor: 'primary.dark',
      }}
    >
      <Box sx={{ marginTop: '-150px',}} >
        <Logo src={logo} width={350} alt={'LettuceDCA Animation'} />
        <Typography paragraph={true}>
          <b>Reach out on X <sub>(fka Twitter) </sub>
            <Link href="https://x.com/lettucedca" target='_blank' sx={{ color: "secondary.main", textDecoration: 'none' }}>@LettuceDCA</Link>. </b><br />
          Share the love by retweeting, providing feedback, <br />
          or sending drops to <br />
          <b>{address}</b> <br />
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
