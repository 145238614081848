import React, { useEffect, useRef } from 'react'
import { currencyFormatter, regNumFormatter } from '../consts'
import axios, { AxiosResponse } from 'axios'
import { Box, Grid, Paper, LinearProgress, styled } from '@mui/material';
import { ILettuceApiState, ILettuceWalletApiResponse, ILettuceStatusResponse } from '../types';

interface DCAProps extends ILettuceApiState, ILettuceWalletApiResponse {
	setProgress: React.Dispatch<React.SetStateAction<number>>
	progress: number
	currency: string
	token: string
}

const DCA: React.FC<DCAProps> = ({ dca, totalSpent, totalTokens, progress, currency,
	setStatus, setApiResponse, setProgress, token}) => {
	let noError = true
	const intervalId = useRef<NodeJS.Timeout | null>(null)

	const fetchData = async () => {
		try {
			const response: AxiosResponse<ILettuceStatusResponse> =
				await axios.get('https://api.lettucedca.com/status', { withCredentials: true })
			setStatus(response.data)
			setApiResponse({
				dca: response.data.dca,
				totalTokens: response.data.totalTokens,
				totalSpent: response.data.totalSpent
			})
			// to account for the way upstream starts progress at negative 100
			setProgress(response.data.progress)
			console.log(response.data)
		} catch (error) {
			console.log(error)
			noError = false
		}
	}

	const startInterval = () => {
		intervalId.current = setInterval(fetchData, 1000)
	}

	const stopInterval = () => {
		if (intervalId.current) {
			clearInterval(intervalId.current)
		}
	}

	useEffect(() => {
		console.log('Effect in progress: ', progress)
		if (intervalId.current === null) {
			startInterval()
		}
		if (progress === 100 || !noError) {
			stopInterval()
		}

	}, [progress])

	const LinearProgressWithColor = styled(LinearProgress)(({ theme }) => ({
  [`& .MuiLinearProgress-barColorPrimary`]: {
    backgroundColor: '#52716d', // your hexadecimal color here
  },
}));

	return (
		<Grid container direction='row' justifyContent='space-between'>
			<Grid item xs={12}>
				<Box sx={{ fontSize: '2em', display: { sm: 'none', md: 'block' } }}>
					Dollar Cost Average
				</Box>
			</Grid>
			<Grid item xs={12} md={4}>
				<Paper style={{
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0,
					height: '100px',
					fontSize: '1.5em',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: '#52716d',
				}}>
					<Box sx={{ display: { sm: 'block', md: 'none' } }}>
						Dollar Cost Average
				</Box>
					<h1>{currencyFormatter(dca, currency)}</h1>
				</Paper>
			</Grid>
			<Grid item xs={6} md={4}>
				<Paper style={{
					borderRadius: 0,
					height: '100px',
					display: 'flex',
					alignItems: 'center',
					color: '#52716d',
					backgroundColor: '#cbe8c8',
					flexDirection: 'column',
					justifyContent: 'center',
				}}>
					<p style={{
						padding: 0,
						margin: '0 10px',
						fontSize: '2.5em',
						fontWeight: 'bold'
					}}>
						{currencyFormatter(totalSpent, currency)}
					</p>
					<h3 style={{
						padding: 0,
						margin: 0,
						fontSize: '1em',
						fontWeight: '300'
					}}>
						TOTAL SPENT
					</h3>
				</Paper>
			</Grid>
			<Grid item xs={6} md={4}>
				<Paper style={{
					borderTopLeftRadius: 0,
					height: '100px',
					borderBottomLeftRadius: 0,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					backgroundColor: '#92eb88',
					color: '#52716d',
					justifyContent: 'center',
				}}>
					<h3 style={{
						padding: 0,
						margin: 0,
						fontSize: '2.5em',
						fontWeight: 'bold'
					}}>
						{regNumFormatter(totalTokens)}
					</h3>
					<p style={{
						padding: 0,
						margin: '0 10px',
						fontSize: '1em',
					}}>
						{token}
					</p>
				</Paper>
			</Grid>
			<Grid item xs={12} md={12}>
					<LinearProgressWithColor variant={'determinate'} value={progress} />
				</Grid>
		</Grid >
	)

	/*return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			alignContent: 'center'
		}}>
			<Grid container item xs={11} sm={11} md={9}
				style={{
					alignItems: 'left',
					display: 'flex',
					color: 'white'
				}}>
				<span style={{ fontSize: '2em' 	}}>
					Dollar Cost Average
				</span>
			</Grid>
			<Grid container spacing={0}	justifyContent='center'>
				<Grid item xs={11} sm={11} md={3}>
					<Paper style={{
						borderTopRightRadius: 0,
						borderBottomRightRadius: 0,
						height: '100px',
						fontSize: '2em',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#52716d',
					}}>
						<h1>{currencyFormatter(dca, currency)}</h1>
					</Paper>
				</Grid>
				<Grid item xs={11} sm={11} md={3}>
					<Paper style={{
						borderRadius: 0,
						height: '100px',
						display: 'flex',
						alignItems: 'center',
						color: '#52716d',
						backgroundColor: '#cbe8c8',
						flexDirection: 'column',
						justifyContent: 'center',
					}}>
						<h3 style={{
							padding: 0,
							margin: 0,
							fontSize: '1.5em',
							fontWeight: '300'
						}}>
							TOTAL SPENT
						</h3>
						<p style={{
							padding: 0,
							margin: '0 10px',
							fontSize: '2.5em',
							fontWeight: 'bold'
						}}>
							{currencyFormatter(totalSpent, currency)}
						</p>
					</Paper>
				</Grid>
				<Grid item xs={11} sm={11} md={3}>
					<Paper style={{
						borderTopLeftRadius: 0,
						height: '100px',
						borderBottomLeftRadius: 0,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						backgroundColor: '#92eb88',
						color: '#52716d',
						justifyContent: 'center',
					}}>
						<h3 style={{
							padding: 0,
							margin: 0,
							fontSize: '2.5em',
							fontWeight: 'bold'
						}}>
							{regNumFormatter(totalTokens)}
						</h3>
						<p style={{
							padding: 0,
							margin: '0 10px',
							fontSize: '1.5em',
							fontWeight: '300'
						}}>
							TOTAL TOKENS
						</p>
					</Paper>
				</Grid>
				<Grid item xs={11} md={9}>
					<LinearProgressWithColor variant={'determinate'} value={progress} />
				</Grid>
			</Grid>
		</Box>
	);*/

}

export default DCA