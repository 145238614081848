import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ILettuceStatusResponse, ILettuceWalletApiResponse } from './types'
import { Home, DCA } from './pages'
import { Header, Footer } from './components'
import { Box, Grid } from '@mui/material'
import logo from './animations/lettuce-dca-logo-2.gif'

const resources = {
  HOME: 'HOME',
  DCA: 'DCA'
}

const App: React.FC = () => {
  const [token, setWeb3Token] = useState<string>('XRP')
  const [body, setBody] = useState<string>(resources.HOME)
  const [fiatCurrency, setFiatCurrency] = useState<string>('USD')
  const [walletAddresses, setWalletAddresses] = useState<string[]>([''])
  const [status, setStatus] = useState<ILettuceStatusResponse>({ totalSpent: 0, progress: 0, dca: 0, totalTokens: 0 })
  const [apiResponse, setApiResponse] = useState<ILettuceWalletApiResponse>({ totalSpent: 0, dca: 0, totalTokens: 0 })
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const handshake = async () => {
    const handshakeUrl = 'https://api.lettucedca.com/handshake'
    const response = await axios.get<string>(handshakeUrl, {
      withCredentials: true
    })
    return response
  }
  // handshake()
  useEffect(() => {
    if (formSubmitted) {
      setBody(resources.DCA)
    }
  }, [formSubmitted])

  useEffect(() => {
    setFormSubmitted(false)
    setBody(resources.HOME)
  }, [token])

  const loadBody = () => {
    switch (body) {
      case resources.HOME:
        return (<Home token={token} setNavigation={setBody} fiatCurrency={fiatCurrency}
          setFiatCurrency={setFiatCurrency} walletAddresses={walletAddresses}
          setWalletAddresses={setWalletAddresses} setStatus={setStatus} formSubmitted={formSubmitted}
          setFormSubmitted={setFormSubmitted} setApiResponse={setApiResponse}
          setProgress={setProgress} setWeb3Token={setWeb3Token} />)
      case resources.DCA:
        return (<DCA setStatus={setStatus}
          setApiResponse={setApiResponse}
          progress={progress} totalSpent={
            progress === 100 ?
              apiResponse.totalSpent : status.totalSpent} dca={apiResponse.dca}
          totalTokens={apiResponse.totalTokens}
          currency={fiatCurrency} setProgress={setProgress} token={token}/>)
    }
  }

  return (
    <Grid container spacing={0} direction='column' justifyContent="space-between" sx={{minHeight: '100vh'}}>
      <Grid item xs={12}>
        <Box sx={{ gridArea: 'header', bgcolor: 'primary.light', }} >
          <Header logoSrc={logo} logoAlt="Company Logo" title="DOLLAR COST AVERAGE"
            subtext='Find the average of your spending' />
        </Box>
      </Grid>
      <Grid item xs={12} >
        <Grid container style={{ justifyContent: 'center' }} >
          <Grid item sx={{ display: { xs: 'hidden' }}} md={3}></Grid>
          <Grid item md={6} xs={10}>
            <Box sx={{ gridArea: 'body', height: '100%', paddingBottom: '200px', paddingTop: '50px' }}>
              {loadBody()}
            </Box>
          </Grid>
          <Grid item sx={{ display: { xs: 'hidden' }}} md={3}></Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ gridArea: 'footer', bgColor: 'primary.dark' }}>
          <Footer network={token} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default App
